@import "../../_styles/global_styles/variables";
@import "../../_styles/global_styles/mixins";
@import "../../_styles/global_styles/extends";
@import "../../_styles/global_styles/animations";

.login-wrapper {
  width: 100%;
  height: 0;
  flex-grow: 1;
  display: grid;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: stretch;

  @include scroll-init(0.3em, $foxy, $white);

  .login-block {
    width: 100%;
    max-width: 25em;
    padding: 1em 2.5em 2.5em;
    justify-self: center;
    border: 1px solid $foxy;
    border-radius: 0.5em;
    background-color: $white;

    @include flex-box($flex-box-col-top-wrap...);
    @include box-shadow(0.12em, 0.25em);

    .login-heading {
      color: $foxy;
      margin: 0.8em auto;
      letter-spacing: 0.1em;

      @include init-font(1.5em, 700);
      @include text-shadow(0.05em, 0.05em);
    }

    .login-text {
      //color: $foxy;
      padding: 0.5em 0;
      max-width: 20em;
    }

    .link-cancel {
      width: 100%;
      text-decoration: none;
      color: $foxyGrey;

      &:hover {
        color: $foxy;
        @include text-shadow(0.05em, 0.05em);
      }
    }
  }
}

#login-form {
  width: 100%;
  margin-bottom: 1.5em;

  .input-wrapper {
    width: 100%;
    height: 3em;
    position: relative;
    margin-top: 1em;
    margin-bottom: 3em;
    border: 1px solid $greyDark;
    border-radius: 0.4em;
    @include transition();

    &:before {
      position: absolute;
      display: block;
      content: "";
      top: 0.9em;
      left: 0.7em;
      padding: 0 0.5em;
      background-color: $white;

      @include transition();
    }

    &[data-name="username"]:before {
      content: "Enter Your username";
    }

    &[data-name="password"]:before {
      content: "Enter Your password";
    }

    &.focused {
      border: 2px solid $foxy;

    }

    &.focused:before {
      top: -0.5em;
      left: 0.7em;

      @include init-font(0.8em, 400);
    }

    .input-wrapper__input {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0.7em;
      border: none;
      border-radius: 0.4em;
      background-color: transparent;
    }

  }

  .error-text {
    position: absolute;
    bottom: -2.2em;
    left: 0.5em;
    color: $red;

    animation: fadeIn 0.5s ease forwards;
  }

  .check-layer {
    width: 100%;
    @include flex-box($flex-box-row-center-wrap...);

    .check-wrapper {
      width: fit-content;
      padding: 0.5em 0;

      .input-label {
        display: inline-block;
        padding: 0.5em;

        @include transition();

        &:hover {
          cursor: pointer;
          color: $foxy;

          @include text-shadow(0.05em, 0.08em);
        }
      }
    }

    .submit-wrapper {
      width: max-content;
      flex-grow: 1;
      text-align: end;
      padding: 0.5em 0;
    }
  }

}
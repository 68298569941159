@import "./variables";

@keyframes popup {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);

  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes popupBackgroundFoxy {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
    background-color: $foxy;
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes scaleUpZero {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "../../_styles/global_styles/_variables.scss";
@import "../../_styles/global_styles/_mixins.scss";
@import "../../_styles/global_styles/_extends.scss";
@import "../../_styles/global_styles/_animations.scss";

.aside-bar {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  max-width: 30em;
  padding: 0.3em;
}
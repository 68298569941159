@import "../../_styles/global_styles/_variables.scss";
@import "../../_styles/global_styles/_mixins.scss";
@import "../../_styles/global_styles/_extends.scss";
@import "../../_styles/global_styles/_animations.scss";

.post-wrapper {
  position: relative;
  width: 100%;
  padding: 0.3em;
  margin: 0.3em 0;
  background-color: $lemonLightest;
  border: 0.15em solid $foxy;

  @include box-shadow(0.12em, 0.25em);
  @include transition();

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    transform: translateY(-0.2em);

    @include box-shadow(0.25em, 0.25em);
  }

  &.viewed {
    background-color: $white;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &.active {
    box-shadow: inset -0.15em -0.1em 0.2em rgba(255,255,255,0.8),
    inset 0.15em 0.15em 0.2em rgba(0,0,0,0.8);
    background-color: rgba(0, 0, 0, 0.1);

    &:hover {
      cursor: initial;
      transform: none;
    }
  }

  .avatar-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 3em;
    overflow: hidden;

    @include flex-box($flex-box-row-center-nowrap...);

    img {
      width: 100%;
    }
  }

  .ratings-wrapper {
    margin-bottom: 0.3em;
  }

  .elem-info {
    font-weight: 600;
  }

  .post-details-wrapper {
    &__userName {
      display: block;
      color: $foxy;
      letter-spacing: 0.05em;
      margin-bottom: 0.3em;
      @include init-font(1.05em, 500);
      @include text-shadow(0.08em, 0.1em);
    }

    &__title {
      margin-bottom: 0.15em;
    }

    &__tags {

    }
  }
}
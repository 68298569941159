@import "../../_styles/global_styles/variables";
@import "../../_styles/global_styles/mixins";
@import "../../_styles/global_styles/extends";
@import "../../_styles/global_styles/animations";

.profile-content {
  width: 100%;
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: stretch;
  padding: 0.5em;

  @include scroll-init(0.3em, $foxy, $white);
/*  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  border: 2px solid $foxy;*/
}

#profile-form {
  align-self: stretch;

  @include flex-box($flex-box-col-top-wrap...);
  gap: 2em;

  .profile-layer {
    width: 100%;

    @include flex-box($flex-box-row-left-wrap...);
    gap: 1em 2em;

    .input-heading {
      width: 100%;
      //text-align: center;

      @include init-font(1.2em, 500);
      @include text-shadow(0.05em, 0.1em);

    }

    .image-section {
      .image-wrapper {
        position: relative;
        width: 15em;
        height: 15em;
        overflow: hidden;
        padding: 0.5em;
        margin-bottom: 1.5em;
        border: 2px solid $foxy;

        @include flex-box($flex-box-row-center-wrap...);
        @include box-shadow(0.2em, 0.3em);

        &:before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset -0.8em -0.8em 2em rgba(238, 121, 0, 0.8),
          inset 0.8em 0.8em 2em rgba(238, 121, 0, 0.8);
        }

        img {
          width: 100%;
        }

        .default-avatar {
          color: $foxy;

          @include init-font(7em, 700);
        }
      }

      .radio-wrapper {

        @include flex-box($flex-box-row-center-nowrap...);
        gap: 0.5em;
      }
    }

    .heading-content {
      flex-grow: 1;

      @include flex-box($flex-box-col-top-wrap...);
    }
  }

  .button-wrapper {
    width: 100%;

    .button {
      margin: 0.5em;
    }
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: auto auto 2em;
    justify-items: start;
    align-items: center;
  }
}
@import "../../_styles/global_styles/variables";
@import "../../_styles/global_styles/mixins";
@import "../../_styles/global_styles/extends";
@import "../../_styles/global_styles/animations";

.default-content-wrapper {
  width: 100%;
  height: 100%;

  @include flex-box($flex-box-row-center-wrap...);

  .default-text {
    text-shadow: 0.05em 0.05em 0.03em rgba(0,0,0,0.5);
    color: $greyLight;

    @include init-font(3em, 700);
  }
}
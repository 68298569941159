@import "../../_styles/global_styles/variables";
@import "../../_styles/global_styles/mixins";
@import "../../_styles/global_styles/extends";
@import "../../_styles/global_styles/animations";

.post-comment-wrapper {
  position: relative;
  max-width: 30em;
  padding: 0.5em;
  margin-bottom: 0.8em;
  border: 0.15em solid $greyDark;
  border-radius: 0.5em;

  @include box-shadow(0.12em, 0.25em);

  &:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: -1.4em;
    bottom: 0.3em;
    border-width: 0.7em;
    border-style: solid;
    border-color: transparent $greyDark $greyDark transparent;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: -1.1em;
    bottom: 0.42em;
    border-width: 0.6em;
    border-style: solid;
    border-color: transparent $greyLightest $greyLightest transparent;
  }

  h3 {
    margin-bottom: 0.3em;
    @include init-font(1.1em, 600);
  }

  p {
    @include init-font(1em, 400);
  }
}
@import "../../_styles/global_styles/_variables.scss";
@import "../../_styles/global_styles/_mixins.scss";
@import "../../_styles/global_styles/_extends.scss";
@import "../../_styles/global_styles/_animations.scss";

.aside-bar__controls {
  width: 100%;
  min-height: 3.5em;
  //padding: 0.3em 0;

  @include flex-box($flex-box-row-center-nowrap...);

  &__buttons {
    padding: 0.3em;
    flex-grow: 1;
    gap: 0.6em;
    @include flex-box($flex-box-row-left-nowrap...);
  }

  &__handles {
    padding: 0.3em;

    @include flex-box($flex-box-row-center-nowrap...);
    gap: 0.5em;
  }

  .avatar-wrapper {
    width: 2.5em;
    padding: 0.15em;
    border: 2px solid $foxy;
    border-radius: 0.5em;
    cursor: pointer;

    @include flex-box($flex-box-row-center-nowrap...);
    @include box-shadow(0.12em, 0.2em);
    @include transition();

    &:hover {
      @include box-shadow(0.2em, 0.2em);
    }

    &.active {
      box-shadow: none;
      background-color: $foxy;
    }

    img {
      width: 100%;
    }
  }
}
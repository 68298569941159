///dimensions
$maxWidth: 1440px;
$minWidth: 480px;

///colors
$white: rgb(255, 255, 255);
$greyLightest: rgb(241, 243, 246);
$greyLighter: rgb(230, 233, 240);
$greyLight: rgb(210, 210, 210);
$greyNorm: rgb(118, 118, 118);
$greyDark: rgb(51, 51, 51);
$dark: rgb(15, 15, 15);
$blue: rgb(0, 57, 117);
$blueDark: rgb(0, 41, 86);
$lemon: rgb(239, 216, 11);
$lemonLight: rgb(252, 245, 182);
$lemonLightest: rgb(249, 245, 210);
$foxy: rgb(238, 121, 0);
$foxyGrey: rgb(239, 177, 124);
$violet: rgb(90, 16, 148);
$red: rgb(229, 0, 27);
$redLight: rgb(255, 138, 151);
$greenDarker: rgb(8, 137, 6);
$green: rgb(12, 211, 9);
$greenLight: rgb(115, 249, 113);
@import "../../_styles/global_styles/variables";
@import "../../_styles/global_styles/mixins";
@import "../../_styles/global_styles/extends";
@import "../../_styles/global_styles/animations";

.post-content {
  width: 100%;
  height: 0;
  flex-grow: 1;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: stretch;
  padding-right: 0.5em;

  @include scroll-init(0.3em, $foxy, $white);

  .content-layer {
    width: 100%;
    margin-bottom: 0.8em;

    &.layer-body {
      @include flex-box($flex-box-col-left-wrap...);
    }

    &.layer-comment {
      @include flex-box($flex-box-col-right-wrap...);
    }
  }
}


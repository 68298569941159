@import "../../_styles/global_styles/variables";
@import "../../_styles/global_styles/mixins";
@import "../../_styles/global_styles/extends";
@import "../../_styles/global_styles/animations";

.post-content-item-wrapper {
  max-width: 30em;
  padding: 0.5em;
  border: 0.15em solid $foxy;
  border-radius: 0.5em;
  background-color: $white;
  @include box-shadow(0.12em, 0.25em);

  .post-title {
    color: $foxy;
    margin-bottom: 0.5em;

    @include init-font(1.1em, 600);
  }

  .post-body {
    line-height: 1.3em;

    @include init-font(1em, 400);
  }
}
@font-face {
  font-family: "HelveticaNeue";
  font-weight: normal;
  font-style: normal;
  src: local('☺');
  src: url('../../_asset/fonts/HelveticaNeue.ttf') format("truetype");
}

@font-face {
  font-family: "StardosStencil-Regular";
  font-weight: normal;
  font-style: normal;
  src: local('☺');
  src: url("../../_asset/fonts/StardosStencil-Regular.ttf") format("truetype");
}
@import "../../_styles/global_styles/variables";
@import "../../_styles/global_styles/mixins";
@import "../../_styles/global_styles/animations";

#alert-wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $greyLight;
  opacity: 0.8;

  @include flex-box($flex-box-row-center-wrap...);

  .alert-block {
    max-width: 25em;
    padding: 1.5em;
    opacity: 1;
    animation: popup 0.3s ease-in-out;

    @include init-font(1em, 700);
    @include flex-box($flex-box-col-center-wrap...);

    &.alert-loading {
      color: $blueDark;
    }

    &.alert-error {
      color: $red;
      background-color: $greyLightest;
      @include box-shadow(0.2em, 0.4em);
    }

    .loader {
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      position: relative;
      animation: rotate 1s linear infinite;

      &:before, &:after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0;
        border-radius: 50%;
        border: 5px solid #FFF;
        animation: prixClipFix 2s linear infinite ;
      }

      &:after {
        border-color: #FF3D00;
        animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
        inset: 6px;
      }

      @keyframes rotate {
        0%   {transform: rotate(0deg)}
        100%   {transform: rotate(360deg)}
      }

      @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
      }
    }

    .alert-content-block {
      padding: 1em;
      @include flex-box($flex-box-col-center-wrap...);

      .alert-text {
        margin-bottom: 0.5em;
      }
    }
  }
}